import * as React from "react";
import { Link } from "gatsby";

const Footer = (props) => {
  const {menuIsOpen, closeMenuModal} = props;

    return (
      <footer id="site-footer" className={`footer ${menuIsOpen ? 'menu-open' : ''}`}>
        <div className="is-flex is-align-items-flex-end is-justify-content-space-between" >
          <div className="flex-left is-flex is-align-items-center">
            <div className="copyright">
              <span>® HOF {(new Date().getFullYear())} &nbsp;</span>
            </div>
            <Link className="footer-link" to="/terms" onClick={closeMenuModal()}>Legal</Link>
          </div>
          <div className="address">
            <span>½ Bond Street NYC</span>
          </div>
        </div>
      </footer>
    );
};

export default Footer;
